import './App.css';
import Signup from './signup';
import WelcomePage from './welcome';

function App() {
  return (
    <div className="App">
     <WelcomePage />
    
    </div>
  );
}

export default App;
