import React, { useState } from "react";

const Signup = () => {
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // State to manage loading state

    const handleSignup = (e) => {
        e.preventDefault();
        setIsLoading(true); // Show loading spinner
        setTimeout(() => {
            setSubmitted(true); // Show coming soon after loading
            setIsLoading(false); // Hide loading spinner
        }, 2000); // Simulate a 2-second delay (e.g., for form submission)
    };

    return (
        <>
            {!submitted ? (
                <form onSubmit={handleSignup}>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        className="input-field"
                        required
                    />
                    <input
                        type="password"
                        placeholder="Enter your password"
                        className="input-field"
                        required
                    />
                    <button 
                        type="submit" 
                        className="signup-button"
                        disabled={isLoading} // Disable button during loading
                    > 
                        {isLoading ? (
                            <div className="spinner"></div> // Show spinner while loading
                        ) : (
                            "Sign Up"
                        )}
                    </button>
                </form>
            ) : (
                <div className="coming-soon">
                    <h1>Thank you for your interest! The Application is still under Development</h1>
                    <p>Coming Soon!</p>
                    <p><strong>Beta Launch: </strong>End of February 2025 (Tentative)</p>
                    <p><strong>Official Launch: </strong>End of March 2025 (Tentative)</p>
                </div>
            )}
            <style jsx>{`
                form {
                    width: 100%;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    text-align: center;
                    box-sizing: border-box;
                    align-items: center;
                }

                .input-field {
                    width: 75%;
                    padding: 15px;
                    border: none;
                    border-bottom: 1px solid #ccc;
                    background: none;
                    font-size: 1rem;
                    font-family: Arial, sans-serif;
                    margin-left: 10%;
                    text-align: center;
                }

                .input-field::placeholder {
                    color: #aaa;
                    text-align: center;
                }

                .input-field:focus {
                    outline: none;
                    border-bottom: 1px solid #007bff;
                }

                .signup-button {
                    width: 55%;
                    padding: 15px;
                    background-color: red;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    font-size: 1.1rem;
                    font-family: SF Pro;
                    transition: background-color 0.3s;
                    margin-left: 10px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .signup-button:hover {
                    background-color: black;
                }

                .spinner {
                    border: 4px solid #f3f3f3;
                    border-top: 4px solid #007bff;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    animation: spin 1s linear infinite;
                }

                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }

                .coming-soon {
                    text-align: center;
                    font-size: 1.2rem;
                    color: #333;
                }

                .coming-soon h1 {
                    font-size: 1.8rem;
                    font-weight: bold;
                }

                /* Responsive Styles */
                @media (max-width: 768px) {
                    form {
                        max-width: 90%;
                    }

                    .input-field {
                        font-size: 1.1rem;
                    }

                    .signup-button {
                        font-size: 1.1rem;
                    }

                    .coming-soon h1 {
                        font-size: 1.5rem;
                    }

                    .coming-soon p {
                        font-size: 1.1rem;
                    }
                }

                @media (max-width: 480px) {
                    form {
                        max-width: 95%;
                    }

                    .input-field {
                        font-size: 1rem;
                        padding: 12px;
                    }

                    .signup-button {
                        font-size: 1rem;
                        padding: 12px;
                    }

                    .coming-soon h1 {
                        font-size: 1.4rem;
                    }

                    .coming-soon p {
                        font-size: 1rem;
                    }
                }
            `}</style>
        </>
    );
};

export default Signup;
