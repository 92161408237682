import React, { useState, useEffect } from "react";
import Signup from "./signup"; // Assuming your Signup component is correct

const WelcomePage = () => {
    const [animationIndex, setAnimationIndex] = useState(0);
    const title = "Welcome to Lucifer."; // Typing animation title

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimationIndex((prevIndex) => (prevIndex + 1) % (title.length + 1));
        }, 150); // Typing speed, adjust as needed
        return () => clearInterval(interval);
    }, [title.length]);

    return (
        <div style={{ display: "flex", height: "100vh", margin: "0", padding: "0", flexDirection: "row" }}>
            {/* Left Section (Static Background Image) */}
            <div
                style={{
                    backgroundImage: "url('https://images6.alphacoders.com/752/752073.jpg')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "28%",
                    height: "100vh",
                    position: "fixed",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    textAlign: "center",
                    overflow: "hidden",
                    zIndex: 1,
                    position: "fixed",
                }}
            >
                {/* Static Background Text Animation with Enhancements */}
                <div
                    style={{
                        fontSize: "1.2rem", // Increased font size for more impact
                        fontFamily: "SF Pro",
                        fontWeight: "bold",
                        zIndex: 10,
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)", // Added shadow for better visibility
                        color: "white", // White text for contrast
                        textTransform: "uppercase", // Making the text uppercase for more emphasis
                        letterSpacing: "2px", // Slight letter spacing for better readability
                        backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent black background behind the text
                        padding: "20px", // Add some padding around the text for better spacing
                        borderRadius: "10px", // Rounded corners for a softer look
                    }}
                >
                    {title.slice(0, animationIndex)}
                </div>
            </div>

            {/* Right Section (Plain White Background) */}
            <div
                style={{
                    backgroundColor: "white",
                    width: "70%",  // Adjusted the width here for the right section to take up the remaining space
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    right: 0,
                    padding: "10px", // Adjust padding for smaller screens
                    boxSizing: "border-box",
                }}
            >
                {/* Form Container */}
                <div
                    style={{
                        width: "100%",
                        maxWidth: "500px", // Adjusted max width for smaller screens
                        padding: "20px",
                        textAlign: "center",
                    }}
                >
                    {/* Logo Section */}
                    <div
                        style={{
                            marginBottom: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px", // Add some padding around the logo
                        }}
                    >
                        <img
                            src="luciferremovebgpreview1.png"
                            alt="Logo"
                            style={{
                                width: "90%", // Makes the logo size responsive based on the screen width
                                maxWidth: "600px", // Ensures the logo doesn't get too large on bigger screens
                                height: "80%", // Maintains the aspect ratio
                                maxHeight: "250px", // Limits the height for larger screens
                            }}
                        />
                    </div>

                    <h1
                        style={{
                            marginBottom: "20px",
                            fontSize: "2rem", // Adjusted heading size for small screens
                            fontWeight: "bold",
                            color: "#333",
                            fontFamily: "SF Pro",
                        }}
                    >
                        Sign Up Now!
                    </h1>
                    <Signup />

                    {/* Placeholder Style */}
                    <style jsx>{`
                        input[type="text"],
                        input[type="password"],
                        input[type="email"] {
                            width: 100%;
                            padding: 12px; /* Reduced padding for small screens */
                            margin: 8px 0; /* Reduced margin for small screens */
                            border: 1px solid #ccc;
                            border-radius: 5px;
                            font-size: 1rem;
                            font-family: SF Pro;
                            box-sizing: border-box;
                        }

                        input::placeholder {
                            color: #888;
                            font-size: 1rem;
                            text-align: center;
                        }

                        button {
                            width: 50%;
                            padding: 12px;
                            background-color: #007bff;
                            color: white;
                            border: none;
                            border-radius: 5px;
                            cursor: pointer;
                            font-size: 1rem;
                        }

                        button:hover {
                            background-color: #0056b3;
                        }

                        /* Responsive Styles */
                        @media (max-width: 768px) {
                            /* Make the left section take full width on smaller screens */
                            .left-section {
                                width: 100%;
                                height: 50vh; /* Reduced height for small screens */
                            }

                            .right-section {
                                width: 100%;
                                height: 50vh;
                            }

                            .title {
                                font-size: 2.5rem; /* Adjusted title font size for small screens */
                            }

                            .form-container {
                                width: 100%;
                                padding: 15px;
                            }

                            img {
                                width: 120px; /* Further reduced logo size */
                            }

                            h1 {
                                font-size: 1.8rem; /* Reduced heading font size */
                            }

                            /* Ensure the form fields are compact */
                            input[type="text"],
                            input[type="password"],
                            input[type="email"] {
                                font-size: 0.9rem;
                                padding: 10px;
                            }

                            button {
                                font-size: 0.9rem;
                                padding: 10px;
                            }
                        }

                        /* Even smaller screen adjustments */
                        @media (max-width: 480px) {
                            /* Make the form take more width */
                            .form-container {
                                width: 100%;
                                padding: 10px;
                            }

                            img {
                                width: 100px; /* Adjusted logo size for even smaller screens */
                            }

                            h1 {
                                font-size: 1.6rem; /* Adjusted heading size for smaller screens */
                            }

                            input[type="text"],
                            input[type="password"],
                            input[type="email"] {
                                font-size: 0.85rem; /* Further reduced font size */
                            }

                            button {
                                font-size: 0.85rem; /* Reduced button font size */
                                padding: 8px;
                            }
                        }
                    `}</style>
                </div>
            </div>
        </div>
    );
};

export default WelcomePage;
